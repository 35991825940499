[data-theme="dark"] {
    --header-color: white;
}   

/* 2 */
[data-theme="light"] {
    --header-color: #16181d;
}

.Projects-container {
    color: var(--header-color);
    text-align: left;
    height: 100%;
    margin-left: 50px;
    margin-right: 50px;
}

.Description {
    text-align: justify;
    -ms-text-justify: distribute-all-lines;
    text-justify: distribute-all-lines;
    min-width: 100%;
}

.Projects-title {
    font-size: 50px;
    padding-bottom: 50px;
}

@media  (pointer:coarse) {
    .Projects-container {
        margin-left: 0px;
        margin-right: 12.5px;
    }
}

@media only screen and (max-width: 1175px) {
    .Projects-container {
        margin-left: 0px;
        margin-right: 12.5px;
    }
}