[data-theme="dark"] {
    --footer-color: #111215;
    --trademark-color: rgb(157, 157, 157);
}   

/* 2 */
[data-theme="light"] {
    --footer-color: #00566d;
    --trademark-color: rgb(157, 157, 157);
}

.Footer-container {
    background-color: var(--footer-color);
    width: 100%;
    height: 300px;
    text-align: center;
    padding-top: 50px;
}

.icon {
    width: 75px;
    padding: 50px;
}

.trademark-container {
    color: white;
    font-size: 20px;
}

.trademark {
    color: var(--trademark-color)
}

@media  (pointer:coarse) {
    .icon {
        width: 50px;
        padding: 25px;
    }
    .Footer-container {
        height: 250px;
        text-align: center;
    }
}
  
@media only screen and (max-width: 1175px) {
    .icon {
        width: 50px;
        padding: 25px;
    }
    .Footer-container {
        height: 250px;
        text-align: center;
    }
}