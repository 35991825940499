[data-theme="dark"] {
    --text-colour: #61dafb;
    --link-hover-colour: white;
}
  
  /* 2 */
[data-theme="light"] {
    --text-colour: #007b9d;
    --link-hover-colour: #16181d;
}

@media only screen and (max-width: 1500px) {
    .Header-link {
        display: none;
    }
}

@media only screen and (min-width: 1500px) {
    .Hamburger-image {
        display: none;
    }

    .Hamburger-link {
        display: none;
    }
}


.Header-container {
    color: var(--text-colour);
    height: 100px;
    flex-flow: nowrap;
    justify-content: flex-start;
    margin-right: 250px;
    margin-left: 250px;
    font-weight: 500;
}
.Hamburger-image:hover {
    opacity: 70%; 
}
.Header-name:hover {
    color: var(--link-hover-colour);
}
@media (pointer:coarse) {
    .Hamburger-image:hover {
        opacity: 100%; 
    }
    .Header-name:hover {
        color: var(--text-colour);
    }
    .Header-container {
        color: var(--text-colour);
        height: 100px;
        flex-flow: nowrap;
        justify-content: flex-start;
        margin-right: 0;
        margin-left: 0;
    }
}


.Header-name {
    float: left;
    padding: 50px;
    font-size: 20px;
    color: var(--text-colour);
    text-decoration: none;
}

.Header-link {
    float: right;
    padding: 50px;
    font-size: 20px;
    text-align: center; 
    color: var(--text-colour);
    text-decoration: none;
}

.Hamburger-menu-link {
    float: right;
    padding: 50px 50px 0 50px;
    width: 50px; 
}


.Hamburger-image {
    float: right;
    width: 50px; 
}

.Hamburger-menu {
    float: right;
    padding: 50px 50px 0 50px;
    font-size: 20px;
    text-align: center; 
    color: var(--text-colour);
    text-decoration: none;
    width: 50px; 
}

.Header-link:hover {
    color: var(--link-hover-colour);
}

@media  (pointer:coarse) {
    .Hamburger-image {
        width: 35px; 
    }
}

@media only screen and (max-width: 1175px) {
    .Hamburger-image:hover {
        opacity: 100%; 
    }
    .Header-name:hover {
        color: var(--text-colour);
    }
    .Header-container {
        color: var(--text-colour);
        height: 100px;
        flex-flow: nowrap;
        justify-content: flex-start;
        margin-right: 0;
        margin-left: 0;
    }
}