[data-theme="dark"] {
    --paragraph-color: white;
    --project-title-color: #959595;
    --link-color: #61dafb;
    --link-color-hover: white;
    --p-background-color: #141d26;
}   

/* 2 */
[data-theme="light"] {
    --paragraph-color: #16181d;
    --project-title-color: #4d4d4d;
    --link-color: #007b9d;
    --link-color-hover: #16181d;
    --p-background-color: #bdeaf6;
}

.Link {
    color: var(--link-color);
    text-decoration: none;
    font-size: 20px;
}

.Link:hover {
    text-decoration: none;
    color: var(--link-color-hover);
}

.Description {
    width: 500px;
    font-size: 20px;
}

a {
    text-decoration: none;
    color: var(--link-color);
}

.Project-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

a:hover {
    color: var(--link-color-hover);
}

@media only screen and (max-width: 1900px) {

    .Project-image {
        width: 90%;
    }
}

@media only screen and (max-width: 1450px) {
    .ImageContainer {
        display: none;
    }

    .Project-image {
        display: none;
    }
}

.Project-title {
    color: var(--project-title-color);
}

.Project-container {
    height: auto;
    width: 90%;
    background-color: var(--p-background-color);
    margin-bottom: 100px;
    margin-right: 10%;
    padding: 50px;
    display: flex;
}

.Project {
    height: auto;
    color: var(--paragraph-color);
    width: 500px;
    text-align: left;
    flex: 1;
    padding-right: calc(10% - 55px);
    padding-left: calc(10% - 55px);
}

.ImageContainer {
    flex: 1;
    width: 500px;
    margin: auto;
}

@media  (pointer:coarse) {
    .Project-container {
        height: auto;
        padding: 25px;
    }
    .Project {
        height: auto;
        width: 100%;
        text-align: left;
        margin-bottom: 50px;
    }
    .Description {
        width: 100%;
        font-size: 20px;
    }
}

@media only screen and (max-width: 1175px) {
    .Project-container {
        height: auto;
        padding: 25px;
    }
    .Project {
        height: auto;
        width: 100%;
        text-align: left;
        margin-bottom: 50px;
    }
    .Description {
        width: 100%;
        font-size: 20px;
    }
 }
 

