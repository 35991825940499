[data-theme="dark"] {
    --paragraph-color: white;
    --company-color: #61dafb;
    --position-color: #959595;
    --year-color: #c4c4c4;
    --link-color-hover: white;
    --w-background-color: #141d26;
    
}   

/* 2 */
[data-theme="light"] {
    --paragraph-color: #16181d;
    --company-color: #007b9d;
    --position-color: #4d4d4d;
    --year-color: #383838;
    --link-color-hover: #16181d;
    --w-background-color: #bdeaf6;
}

.WorkExperience-container {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 100px;
    padding-right: 100px;
    background-color: var(--w-background-color);
    margin-bottom: 50px;
    margin-right: 50px;
}

.WorkExperience-position {
    color: var(--position-color);
}

.WorkExperience-year {
    color: var(--year-color);
}

.WorkExperience-company-link {
    text-decoration: none;
    color: var(--company-color);
}

.WorkExperience-company-link:hover {
    text-decoration: none;
    color: var(--link-color-hover);
}

@media  (pointer:coarse) {
    .WorkExperience-container {
        padding: 25px;
        margin-bottom: 50px;
        margin-right: 0;
    }
}

@media only screen and (max-width: 1175px) {
    .WorkExperience-container {
        padding: 25px;
        margin-bottom: 50px;
        margin-right: 0;
    }
 }
 