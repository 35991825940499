[data-theme="dark"] {
  --background-image: url(/src/lib/Backgrounds/darkBackground.svg)
}

/* 2 */
[data-theme="light"] {
  --background-image: url(/src/lib/Backgrounds/lightBackground.svg)
}

.App-container {
  width: 100%;
  height: 100%;
}


.App {
  text-align: center;
  height: 100%;
  margin-top: 200px;
  margin-left: 250px;
  margin-right: 250px;
  display: flex;
  flex-direction: column;
}
@media  (pointer:coarse) {
  .App {
    text-align: center;
    height: 100%;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 1175px) {
  .App {
    text-align: center;
    height: 100%;
    margin-top: 50px;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
  }
}


body {
  background-image: var(--background-image)
}