.Scroll {
   position: fixed; 
   width: 50px;
   right: 50px;
   bottom: 40px;
   font-size: 3rem;
   z-index: 1;
   cursor: pointer;
}

@media  (pointer:coarse) {
   .Scroll {
      right: 10px;
      font-size: 1rem;
   }
}

@media only screen and (max-width: 1175px) {
   .Scroll {
      right: 10px;
      font-size: 1rem;
   }
}
