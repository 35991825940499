[data-theme="dark"] {
    --title-color: white;
  }
  
  /* 2 */
  [data-theme="light"] {
    --title-color: #16181d;
  }

.WorkExperiences-container {
    text-align: left;
    margin-left: 50px;
    padding-bottom: 200px;
}

.WorkExperiences-title {
    font-size: 50px;
    color: var(--title-color);
}

.WorkExperiences-flexbox {
    text-align: justify;
    -ms-text-justify: distribute-all-lines;
    text-justify: distribute-all-lines;
}

@media  (pointer:coarse) {
  .WorkExperiences-container {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 200px;
  }
  .WorkExperiences-flexbox {
    text-align: left;
    -ms-text-justify: none;
    text-justify: none;
    min-width: 100%;
  }
}

@media only screen and (max-width: 1175px) {
  .WorkExperiences-container {
    margin-left: 0;
    margin-right: 0;
    padding-bottom: 200px;
  }
  .WorkExperiences-flexbox {
    text-align: left;
    -ms-text-justify: none;
    text-justify: none;
    min-width: 100%;
  }
}