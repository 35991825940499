[data-theme="dark"] {
    --text-colour: #61dafb;
    --link-hover-colour: white;
}
  
  /* 2 */
[data-theme="light"] {
    --text-colour: #007b9d;
    --link-hover-colour: #16181d;
}

@keyframes growDown {
    0% {
        transform: scaleY(0)
    }
    80% {
        transform: scaleY(1.1)
    }
    100% {
        transform: scaleY(1)
    }
}

.Hamburger-links-container {
    position: absolute;
    top: 110px;
    text-align: right;
    right: 300px;
    overflow: hidden;
    animation: growDown 300ms ease-in-out forwards;
    transform-origin: top center
}

@media only screen and (max-width: 1175px) {
    .Hamburger-links-container {
        position: absolute;
        top: 110px;
        text-align: right;
        right: 50px;
        overflow: hidden;
        animation: growDown 300ms ease-in-out forwards;
        transform-origin: top center
    }
}
@media (pointer:coarse) {
    .Hamburger-links-container {
        position: absolute;
        top: 110px;
        text-align: right;
        right: 50px;
        overflow: hidden;
        animation: growDown 300ms ease-in-out forwards;
        transform-origin: top center
    }
}

.Hamburger-link {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: right;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    color: var(--text-colour);
    text-decoration: none;
}

.Hamburger-link:hover {
    color: var(--link-hover-colour);
}

@media only screen and (min-width: 1500px) {
    .Hamburger-link {
        display: none;
    }

    .Hamburger-links-container {
        display: none;
    }
}