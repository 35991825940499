[data-theme="dark"] {
    --header-color: white;
    --text-color: #959595;
}   

/* 2 */
[data-theme="light"] {
    --header-color: #16181d;
    --text-color: #4d4d4d;
}

.App-header {
    align-items: left;
    text-align: left;
    color: var(--header-color);
    margin-bottom: 300px;
}

.Introduction {
    font-size: 25px;
    font-weight: 500;
    color: var(--text-color);
    width: 720px;
    top: 200px;
    position: relative;
    margin-left: 50px;
}

.wave {
    animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s;        /* Change to speed up or slow down */
    animation-iteration-count: infinite;  /* Never stop waving :) */
    transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
    display: inline-block;
}

.Name {
    font-size: 100px;
    text-align: left;
    position: relative;
    top: 200px;
    margin-left: 50px;
}

@media (pointer:coarse) {
    .App-header {
        margin-bottom: 200px;
    }
    .Introduction {
        font-size: 20px;
        color: var(--text-color);
        width: 100%;
        top: 50px;
        position: relative;
        margin-left: 0px;
        margin-bottom: 50px;
    }

    .wave {
        font-size: 60px;
    }

    .Name {
        font-size: 75px;
        position: relative;
        top: 75px;
        margin-left: 0px;
    }
}

@media only screen and (max-width: 1175px) {
    .App-header {
        margin-bottom: 200px;
    }
    .Introduction {
        font-size: 20px;
        color: var(--text-color);
        width: 100%;
        top: 50px;
        position: relative;
        margin-left: 0px;
        margin-bottom: 50px;
    }

    .wave {
        font-size: 60px;
    }

    .Name {
        font-size: 75px;
        position: relative;
        top: 75px;
        margin-left: 0px;
    }
 }
 
  
@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
    10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
    20% { transform: rotate(-8.0deg) }
    30% { transform: rotate(14.0deg) }
    40% { transform: rotate(-4.0deg) }
    50% { transform: rotate(10.0deg) }
    60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
100% { transform: rotate( 0.0deg) }
}